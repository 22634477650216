import React from 'react'
// import CheckboxList from '../Components/CheckboxList.jsx'
import RadioList from '../Components/RadioList.jsx'
// import Input from '../Components/Input.jsx'
import Textarea from '../Components/Textarea.jsx'

class Survey extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div id='why_div' className='row box'>
          <h3>Why didn't you stay the night at our hotel during your visit?</h3>
          <RadioList options={this.props.options.whyOptions} hasOther={true}
            stateKey='why' keyList={this.props.why} handleInputChange={this.props.handleInputChange}
            other_Key='other_why' other={this.props.other_why}
          />
        </div>

        <div id='challenge_div' className='row box'>
          <h3>If you wanted to stay, what challenges did you face in making your reservation?</h3>
          <RadioList options={this.props.options.challengesOptions} hasOther={true}
            stateKey='challenge' keyList={this.props.challenge} handleInputChange={this.props.handleInputChange}
            other_Key='other_challenge' other={this.props.other_challenge}
          />
        </div>

        <div id='contact_div' className='row box'>
          <h3>Were you contacted by a member of our Host or Junior Host team at all before or during your visit?</h3>
          <RadioList options={this.props.options.contactOptions} hasOther={false} handleInputChange={this.props.handleInputChange}
            stateKey='contact' keyList={this.props.contact}
          />
        </div>

        <div id='other_div' className='row box'>
          <h3>Is there anything else you would like us to know that could help improve your experience at WinStar?</h3>
          <Textarea inputKey='other' handleInputChange={this.props.handleInputChange} value={this.props.other} />
        </div>
      </React.Fragment>
    )
  }
}

export default Survey