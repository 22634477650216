import React from 'react'
import { toast } from 'react-toastify'
import Hotel from './Hotel.jsx'

class Survey extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      PlayerID: '',
      why: [],
      other_why: '',
      challenge: [],
      other_challenge: '',
      selected: [],
      contact: [],
      other: '',

      isSubmitting: false,
      skipped: []
    }
  }

  static getDerivedStateFromProps(prop, state) {
    return { ...state, PlayerID: prop.playerID }
  }

  handleInputChange = e => {
    const check = 'Other. Please explain:'
    let obj = { [e.target.id]: e.target.value }

    if (this.state[e.target.id] === check && e.target.value !== check) {
      obj[`other_${e.target.id}`] = ''
    }

    this.setState(obj)
  }

  handleSubmit = e => {
    e.preventDefault()

    const { skipped, isSubmitting, Visited_Date, Created_Date, Updated_Date, ...obj } = this.state
    let keys = []
    for (let key in obj) {
      if (obj[key] === '') { obj[key] = null }
      else { keys.push(key) }
    }
    obj.keys = keys

    this.setState({ isSubmitting: true })
    fetch(`/api/updateSurvey?${new URLSearchParams(obj).toString()}`).then(result => {
      window.location = '/thanks'
    }).catch(error => {
      this.setState({ isSubmitting: false })
      console.error(error)
      toast.error('There was an problem Saving your Preferences.')
    })
  }

  render() {
    return (
      <React.Fragment>
        <div className='container' id='container1'>
          <div id='scorpio_top'>
            <img alt='WinstarLogo' src={process.env.PUBLIC_URL + '/images/WWCR_LOGO_WHT-GOLD.png'} className='logo' />
          </div>
        </div>

        <div className='container' id='line_container'>
          <hr id='top_line' />
        </div>

        <div className='container-fluid'>
          <div className='col-sm-3'></div>

          <form className='col-sm-6' onSubmit={this.handleSubmit} method='post' noValidate>

            <Hotel options={this.props.options} handleCheckboxChange={this.handleCheckboxChange} handleInputChange={this.handleInputChange}
              why={this.state.why} other_why={this.state.other_why} challenge={this.state.challenge} other_challenge={this.state.other_challenge}
              selected={this.state.selected} contact={this.state.contact} other={this.state.other} />

            <input id='submitButton' type='submit' className='btn btn-secondary' disabled={this.state.isSubmitting} value='Submit' style={{ float: 'right' }} />
          </form>

          <div className='col-sm-3'></div>
        </div>
      </React.Fragment>
    )
  }
}

export default Survey