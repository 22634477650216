import React from 'react'

class Input extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      update: true
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value && state.update) {
      return {
        value: props.value,
        update: false
      }
    }
    return null
  }

  handleChange = e => { this.setState({ value: e.target.value }) }

  render() {
    return (
      <div className='row'>
        <textarea rows={4} id={this.props.inputKey} name={this.props.inputKey} type='text' className='inputs' value={this.state.value} onChange={this.handleChange} onBlur={this.props.handleInputChange} />
      </div>
    )
  }
}

export default Input