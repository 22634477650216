import React from 'react'

class Thanks extends React.Component {
  componentDidMount() {
    this.props.changeLoading()
    setTimeout(function () {
      window.location.replace('https://www.winstar.com/')
    }, 5000)
  }

  render() {
    return (
      <div style={{ height: '100vh' }}>
        <div className='container' id='container1'>
          <div id='scorpio_top'>
            <img alt='WinstarLogo' src={process.env.PUBLIC_URL + '/images/WWCR_LOGO_WHT-GOLD.png'} className='logo' />
          </div>
        </div>

        <div className='container' id='line_container'>
          <hr id='top_line' />
        </div>

        <div className='container-fluid'>
          <div className='col-sm-3'></div>

          <div className='col-sm-6'>
            <div id='thankYouMessage' className='row box' style={{ minHeight: '250px', textAlign: 'center', paddingTop: '80px' }}>
              <h1>Thank you for participating in our survey.</h1>
            </div>

            {/* <input type='button' className='btn btn-secondary' value='Click here to Exit' style={{ float: 'right' }} onClick={onClose} /> */}
          </div>

          <div className='col-sm-3'></div>
        </div>
      </div>
    )
  }
}

export default Thanks