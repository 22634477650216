import React from 'react'

class Errors extends React.Component {
  render() {
    return (
      <div style={{ height: '100vh' }}>
        <div className='container' id='container1'>
          <div id='scorpio_top'>
            <img alt='WinstarLogo' src={process.env.PUBLIC_URL + '/images/WWCR_LOGO_WHT-GOLD.png'} className='logo' />
          </div>
        </div>

        <div className='container' id='line_container'>
          <hr id='top_line' />
        </div>

        <div className='container-fluid'>
          <div className='col-sm-3'></div>

          <div className='col-sm-6'>
            <div className='row box' style={{ minHeight: '250px', textAlign: 'center', paddingTop: '80px' }}>
              <h1>There was a problem retrieving Survey data.<br />Please try again later.</h1>
            </div>

            {/* <input type='button' className='btn btn-secondary' value='Click here to Exit' style={{ float: 'right' }} onClick={onClose} /> */}
          </div>

          <div className='col-sm-3'></div>
        </div>
      </div>
    )
  }
}

export default Errors