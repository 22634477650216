import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate, useParams } from 'react-router-dom'
import Survey from './Survey/Survey.jsx'
import Thanks from './Survey/Thanks.jsx'
import Errors from './Survey/Errors.jsx'
import CircularProgress from '@mui/material/CircularProgress'
import { styled } from "@mui/system"
import { ToastContainer, toast } from 'react-toastify'

const cusStyles = styled(theme => ({ root: { fontSize: 'xxx-large' } }))

const loadingStyle = {
  height: '100vh',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

const CircularIndeterminate = (props) => {
  const classes = cusStyles()
  const { playerID } = useParams()

  useEffect(() => {
    if (props.req) {
      props.getSurvey(playerID).then(() => {
        props.getSurveyOptions()
      })
    }
  })

  return (
    <div className={classes.root}>
      <CircularProgress size={80} thickness={5} />
    </div>
  )
}

function Intermediary(props) {
  let { playerID } = useParams()
  return (<Survey playerID={playerID} options={props.options} playerSurvey={props.playerSurvey} />)
}

class Router extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      req: true,
      options: {},
      playerSurvey: {}
    }
  }

  getSurveyOptions = () => {
    const body = { headers: { 'Content-Type': 'application/json' } }

    fetch(`/api/getSurveyOptions`, body).then(res => res.json()).then(result => {
      this.setState({
        loading: false,
        options: {
          challengesOptions: result.challengesOptions,
          contactOptions: result.contactOptions,
          selectedOptions: result.selectedOptions,
          whyOptions: result.whyOptions
        }
      })
    }).catch(error => {
      console.error(error)
      toast.error('There was a problem gathering Survey Options.')
      window.location = '/error'
    })
  }

  getSurvey = playerID => {
    this.setState({ req: false })
    return fetch(`/api/getSurvey?ID=${playerID}`).then(res => res.json()).then(result => {
      if (result[0].Created_Date) { window.location = '/thanks' }
    }).catch(error => {
      console.error(error)
      toast.error('There was a problem getting Player Survey.')
      window.location = '/error'
    })
  }

  changeLoading = () => { this.setState({ loading: !this.state.loading }) }

  render() {
    return (
      <React.Fragment>
        <BrowserRouter>
          <Routes>
            <Route path='/survey/:playerID' element={
              this.state.loading ?
                <div className='row' style={loadingStyle}>
                  <CircularIndeterminate getSurveyOptions={this.getSurveyOptions} getSurvey={this.getSurvey} req={this.state.req} />
                </div>
                :
                <Intermediary options={this.state.options} playerSurvey={this.state.playerSurvey} />
            } />
            <Route path='/thanks' element={<Thanks changeLoading={this.changeLoading} />} />
            <Route path='/error' element={<Errors changeLoading={this.changeLoading} />} />
            <Route path='/' render={() => <Navigate from='/' to='/thanks' />} />
          </Routes>
        </BrowserRouter>

        <ToastContainer
          position='bottom-right'
          autoClose={4000}
          draggable
          pauseOnHover
          closeOnClick
        />
      </React.Fragment>
    )
  }
}

export default Router