import React from 'react'
import { RadioGroup, Radio, FormControlLabel } from '@mui/material'

class RadioList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      update: true
    }
  }

  static getDerivedStateFromProps(prop, state) {
    if (prop.other !== state.value && state.update) {
      return {
        value: prop.other,
        update: false
      }
    } else {
      return {}
    }
  }

  handleChange = e => { this.setState({ value: e.target.value }) }

  handleBlur = e => {
    this.props.handleInputChange(e)
    this.setState({ update: true })
  }

  render() {
    return (
      <React.Fragment>
        <RadioGroup name={this.props.stateKey} id={this.props.stateKey} value={this.props.keyList} onChange={this.props.handleInputChange}>
          {this.props.options.map(team => (
            <React.Fragment>
              <FormControlLabel labelPlacement='end' key={Math.random()} value={team.Options}
                control={<Radio color='default' id={this.props.stateKey} name={team.Options}
                  sx={{
                    transform: 'scale(1.5)',
                    color: '#C9B44E',
                    '&$checked': { color: '#C9B44E' }
                  }}
                />}
                label={team.Options}
                sx={{
                  fontSize: '20px',
                  fontFamily: "'Helvetica', sans-serif",
                  fontWeight: 700
                }}
              />
              {this.props.hasOther && team.Options === 'Other. Please explain:' && <div className='row col-lg-6'>
                <input id={this.props.other_Key} name={this.props.other_Key} type='text' className='inputs' disabled={this.props.keyList.indexOf('Other') === -1} value={this.state.value} onChange={this.handleChange} onBlur={this.handleBlur} />
              </div>}
            </React.Fragment>
          ))}
        </RadioGroup>
      </React.Fragment>
    )
  }
}

export default RadioList